@import url('https://fonts.googleapis.com/css?family=Poppins');

body[data-theme='dark'] {
  --font-color: rgb(240, 240, 240);
  --background-color: rgb(10, 10, 10);
  --card-hover-bg: rgba(255, 255, 255, 0.12);
  --svg-stroke-color: rgb(10, 10, 10);
}

body[data-theme='light'] {
  --font-color: rgb(10, 10, 10);
  --background-color: rgb(240, 240, 240);
  --card-hover-bg: rgba(0, 0, 0, 0.12);
  --svg-stroke-color: rgb(240, 240, 240);
}

body {
  font-family: 'Poppins';
  background-color: var(--background-color);
  color: var(--font-color);
  transition-property: color, background-color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.Header {
  padding: 2%;
  font-weight: 600;
  color: var(--font-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header__inner {
  display: flex;
  align-items: center;
}

.Header__inner svg {
  width: 120px;
  height: auto;
  margin: 10px 0 0 10px;
}

svg .a,
.c {
  fill: none;
  stroke: var(--font-color);
}
svg .a,
.b,
.c {
  stroke-miterlimit: 10;
}
svg .a {
  stroke-width: 4px;
}
svg .b {
  fill: var(--font-color);
  stroke: var(--svg-stroke-color);
  stroke-width: 2px;
}
svg .c {
  stroke-width: 2px;
}

img {
  width: 46px;
  height: 46px;
  border-radius: 75%;
  margin-top: 10px;
}

.row {
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

a,
a:hover {
  text-decoration: none;
  color: var(--font-color);
}

.Card {
  margin-top: 5%;
  margin-right: 2%;
  padding: 8px;
  border-radius: 8px;
  height: 200px;
}

.Card:hover {
  background: var(--card-hover-bg);
  border: 1px solid hsla(0, 0%, 87.8%, 0);
}

.data {
  margin-top: 5px;
}

h2 {
  font-size: 140%;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 1px;
  display: inline;
}

p {
  font-size: 100%;
  font-weight: 400;
  color: rgb(70, 70, 70);
  margin-bottom: 5px;
}

.cover {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  height: 94px;
}

.Footer {
  font-size: small;
  text-align: center;
}

.heart {
  color: #db2929;
}

.Footer > p {
  font-weight: 600;
}

.switch-theme-button {
  background-color: #4fd1fe;
  border-radius: 100px;
  box-shadow: rgba(18, 179, 235, 0.2) 0 -25px 18px -14px inset, rgba(18, 179, 235, 0.15) 0 1px 2px, rgba(18, 179, 235, 0.15) 0 2px 4px,
    rgba(18, 179, 235, 0.15) 0 4px 8px, rgba(18, 179, 235, 0.15) 0 8px 16px, rgba(18, 179, 235, 0.15) 0 16px 32px;
  color: #3e7cf4;
  cursor: pointer;
  display: flex;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.switch-theme-button {
  box-shadow: rgba(18, 179, 235, 0.35) 0 -25px 18px -14px inset, rgba(18, 179, 235, 0.25) 0 1px 2px, rgba(18, 179, 235, 0.25) 0 2px 4px,
    rgba(18, 179, 235, 0.25) 0 4px 8px, rgba(18, 179, 235, 0.25) 0 8px 16px, rgba(18, 179, 235, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}
